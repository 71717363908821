import * as React from 'react';

import Colors from '../../../style/Colors';

export default function Path(props) {
  return props.array && (
    props.array.map((value, index) => (
      <path
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        d={value.d}
        stroke={
          !props.isSolid
            ? (props.color ?? Colors.Critical)
            : 'initial'
        }
        strokeLinecap={
          !props.isSolid ? 'round' : 'initial'
        }
        strokeLinejoin={
          !props.isSolid ? 'round' : 'initial'
        }
        strokeWidth={
          !props.isSolid ? 2 : 'initial'
        }
        /** If icon type is solid, use these properties */
        fill={
          !props.isSolid
            ? 'none'
            : (props.color ?? Colors.Critical)
        }
      />
    ))
  );
}
