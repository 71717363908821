import * as React from 'react';

import handleIconValuesFromFile from './functions/handleIconValuesFromFile';

import Path from './Path';
import Line from './Line';

import FlowbiteSolid from '../../../assets/icons/flowbite/solid.json';
import FlowbiteOutlined from '../../../assets/icons/flowbite/outlined.json';

export default function Icon(props) {
  /** Load data based on given dataset */
  const getIconDataset = () => {
    switch (props.dataset) {
      default:
        if (props.type === 'solid') return FlowbiteSolid;
        return FlowbiteOutlined;
    }
  };

  /** Get a specific icon from the dataset */
  const getIconFromFile = () => handleIconValuesFromFile(getIconDataset(), props.name);

  return (
    <svg
      viewBox={getIconFromFile().viewBox}
      fill="none"
      style={{
        height: props.size ?? 24,
        width: props.size ?? 24,
      }}
    >
      <Path
        isSolid={props.type === 'solid'}
        color={props.color}
        array={getIconFromFile().path}
      />
      <Line
        color={props.color}
        array={getIconFromFile().line}
      />
    </svg>
  );
}
