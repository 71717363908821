import * as React from 'react';

import Colors from '../../../style/Colors';

export default function Link(props) {
  const [isHover, setHover] = React.useState(props.isUnderlineVisible ? true : false);

  return (
    <a
      href={props.url}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(props.isUnderlineVisible ? true : false)}
      style={{
        color: Colors.Heading,
        fontWeight: 100,
        fontFamily: 'Lato',
        textDecoration: props.isUnderlineVisible || isHover ? 'underline' : 'none',
        cursor: 'pointer',
        fontSize: '1em',
      }}
    >
      {props.children}
    </a>
  )
}
