import * as React from 'react';

import View from '../../../utilities/View';

import Title from '../../Atoms/Title';
import Paragraph from '../../Atoms/Paragraph';

export default function Content(props) {
  return (
    <View
      width="100%"
      gap={15}
    >
      <Title
        size="1.8em"
        isLoading={props.isLoading}
      >
        {props.title}
      </Title>
      <Paragraph
        hasHTML
        isLoading={props.isLoading}
        lines={3}
      >
        {props.description}
      </Paragraph>
    </View>
  )
}
