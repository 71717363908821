import * as React from 'react';

import View from '../../../utilities/View';

import Icon from '../Icon';

import './ActivityIndicator.css';

export default function ActivityIndicator(props) {
  return props.isVisible && (
      <View
        className="ActivityIndicator"
        justify="center"
        align="center"
        height={props.size ?? 20}
        width={props.size ?? 20}
      >
        <Icon
          name="ActivityIndicator"
          type="outlined"
          size={props.size ?? 20}
          color={props.color}
        />
      </View>
  )
}
