import * as React from 'react';

import Colors from '../../../style/Colors';

export default function Line(props) {
  return props.array && (
    props.array.map((value, index) => (
      <line
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        x1={value.x1}
        y1={value.y1}
        x2={value.x2}
        y2={value.y2}
        stroke={props.color ?? Colors.Critical}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ))
  );
}
