import * as React from 'react';

import getSingleton from '../../../api/getSingleton';

import View from '../../../utilities/View';
import Container from '../../../utilities/Container';

import Colors from '../../../style/Colors';
import Breakpoint from '../../../style/Breakpoints';

import useDimensions from '../../../hooks/useDimensions';

import Links from './Links';
import Contact from './Contact';
import Content from './Content';
import Bottom from './Bottom';

export default function Footer() {
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  const size = useDimensions();

  const gap = size.width <= Breakpoint.sm ? 60 : 60;
  const direction = size.width <= Breakpoint.sm ? 'column' : 'row';

  React.useEffect(() => {
    const handleFetchData = async () => {
      const result = await getSingleton('config');

      await setData(result);

      if (result) setLoading(false);
    }

    handleFetchData();
  }, []);

  return (
    <View
      height="auto"
      width="100%"
      backgroundColor={Colors.White}
    >
      <Container>
        <View
          gap={45}
          paddingTop={60}
        >
          <View
            width="100%"
            gap={60}
            direction={direction}
          >
            <View
              width="100%"
              gap={gap}
            >
              <Content
                title={data.title}
                description={data.description}
                isLoading={isLoading}
              />
              {
                /*
                  <Content
                    title="Locatie"
                    description={data.location}
                    isLoading={isLoading}
                  />
                */
              }
            </View>
            <View
              width="100%"
              gap={gap}
            >
              <Links />
              <Contact
                items={
                  [
                    {
                      id: 0,
                      type: 'email',
                      text: data.email,
                    },
                    {
                      id: 1,
                      type: 'instagram',
                      text: data.instagram,
                    }
                  ]
                }
              />
            </View>
          </View>
          <Bottom
            text={data.copyright}
          />
        </View>
      </Container>
    </View>
  )
}
