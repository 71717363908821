export default function handleMargin(top, right, bottom, left) {
  function handleFormatting(value) {
    return ((value === undefined) || (typeof value === 'string'))
      ? value ?? '0px'
      : `${value}px` ?? '0px';
  }

  return `
    ${handleFormatting(top)}
    ${handleFormatting(right)}
    ${handleFormatting(bottom)}
    ${handleFormatting(left)}
  `;
}
