import * as React from 'react';

import ItemButton from './Button';
import ItemDefault from './Default';

export default function Item(props) {
  const handleType = (type) => {
    switch (props.type) {
      case 'Button':
        return (
          <ItemButton
            key={props.key}
            data={props.data}
            isSideMenu={props.isSideMenu}
          />
        );
      default:
        return (
          <ItemDefault
            key={props.key}
            data={props.data}
            isSideMenu={props.isSideMenu}
          />
        );
    }
  }

  return handleType();
}
