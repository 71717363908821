import * as React from 'react';

import Header from './components/Organisms/Header';
import Footer from './components/Organisms/Footer';

import './style/css/Main.css';

export default function App(props) {
  return (
    <>
      <Header />
      <main>
        {
          props.children
        }
      </main>
      <Footer />
    </>
  )
}
