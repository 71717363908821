import * as React from 'react';

import View from '../../../utilities/View';

import Colors from '../../../style/Colors';

import Title from '../../Atoms/Title';
import Paragraph from '../../Atoms/Paragraph';
import Icon from '../../Atoms/Icon';

export default function Contact(props) {
  const getLink = (item) => {
    switch (item.type) {
      case 'email':
        return `mailto:${item.text}`;
      case 'instagram':
        return `https://www.instagram.com/${item.text?.substr(1)}/`;
    }
  }

  return (
    <View
      width="100%"
      gap={15}
    >
      <Title
        size="1.8em"
      >
        Contact
      </Title>
      <View
        gap={10}
      >
        {
          props.items.map((item) => (
            <View
              key={item.id}
              direction="row"
              align="center"
              gap={15}
            >
              <View
                height={30}
                width={30}
                align="center"
                justify="center"
              >
                <Icon
                  name={item.type === 'email' ? 'Envelope' : 'Instagram'}
                  size={item.type === 'email' ? 23 : 27}
                  color={Colors.Body}
                  type={item.type === 'email' ? 'outlined' : 'solid'}
                />
              </View>
              <a
                href={getLink(item)}
                target="_blank"
                style={{
                  textDecoration: 'inherit',
                }}
              >
                <Paragraph>
                  {item.text}
                </Paragraph>
              </a>
            </View>
          ))
        }
      </View>
    </View>
  )
}
