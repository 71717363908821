import * as React from 'react';

import Colors from '../../../style/Colors';

import './SkeletonIndicator.css';

export default function SkeletonIndicator(props) {
  return (
    <div
      className="SkeletonIndicator"
      style={{
        height: props.height ?? 20,
        width: props.width ?? '100%',
        maxWidth: props.maxWidth ?? '100%',
        backgroundColor: Colors.Body,
        position: 'relative',
        borderRadius: 7.5,
        opacity: 0.2,
      }}
    />
  )
}
