import * as React from 'react';

import LogoSVG from '../../../assets/svg/logo.svg';

export default function Logo() {
  return (
    <a
      href="/"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 210,
        minWidth: 210,
        height: 60,
      }}
    >
      <img
        src={LogoSVG}
        alt="logo"
        style={{
          height: 'auto',
          width: 'auto',
        }}
      />
    </a>
  );
}
