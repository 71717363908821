import * as React from 'react';

import handleThrottling from '../functions/handleThrottling';

export default function useDimensions() {
  const [size, setSize] = React.useState({
    height: 0,
    width: 0,
  });

  React.useEffect(() => {
    setSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, []);

  React.useLayoutEffect(() => {
    /** Handles window resizes so the dimensions stay up-to-date on resize. */
    function onResize() {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleThrottling(onResize, 110));

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return size;
}
