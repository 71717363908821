import * as React from 'react';

import useDimensions from '../../../hooks/useDimensions';

import Colors from '../../../style/Colors';

import handleTextDecoration from '../../../style/functions/handleTextDecoration';
import handleMaxLines from '../../../style/functions/handleMaxLines';
import handleHeading from '../../../style/functions/handleHeading';

export default function Title(props) {
  const size = useDimensions();

  const maxLinesProperties = handleMaxLines(props.maxLines);

  return React.createElement(
    handleHeading(props.heading),
    {
      style: {
        margin: 0,
        padding: 0,
        color: props.color ?? Colors.Heading,
        fontWeight: 300,
        fontSize: props.size ?? '2.3em',
        fontFamily: props.family ?? 'Libre Basketville',
        textAlign: props.align ?? 'left',
        textDecoration: handleTextDecoration(props.decoration ?? 'unset'),
        ...maxLinesProperties,
      },
    },
    props.children,
  );
}
