import * as React from 'react';

import View from '../../../../../utilities/View';

import Item from '../Item';

export default function HorizontalMenu(props) {
  if (!props.isVisible) return null;

  return props.isVisible && (
    <View
      element="nav"
      height={60}
      width="100%"
      direction="row"
      justify="end"
      align="center"
      gap={25}
    >
      {
        props.data.map((item) => (
          <React.Fragment
            key={item.id}
          >
            <Item
              key={item.id}
              data={item}
              type={item.meta.button ? 'Button' : 'Default'}
            />
          </React.Fragment>
        ))
      }
    </View>
  );
}
