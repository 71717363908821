export default function handleOnPress(onPress, isPending) {
  if (isPending) {
    return null;
  }

  if (typeof onPress === 'string' || onPress instanceof String) {
    return `${onPress}`;
  }

  return () => onPress();
}
