import axios from 'axios';

import Api from '../config/Api.config';

export default async function getMenu(name) {
  let result = [];

  await axios.get(`${process.env.API_URL}/pages/menus`, {
    headers: {
      'api-key': Api.Key,
    }
  })
    .then((e) => result = e.data.find((item) => item.name === name))
    .catch((error) => {
      throw new Error(error)
    })

  return result;
}
