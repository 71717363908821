const React = require('react');

const App = require('./src/App').default;

/**
 * Note:
 *
 * The APIs wrapPageElement exist in both the browser and Server Side Rendering
 * SSR APIs. You generally should implement the same components in both
 * 'gatsby-ssr.js' and 'gatsby-browser.js'. So that pages generated through
 * SSR are the same after being hydrated in the browser.
 */
exports.wrapPageElement = ({ element }) => (
  <App>
    {element}
  </App>
);
