export default function handleIconValuesFromFile(file, name) {
  const defaultValues = {
    viewBox: '0 0 0 0',
    path: false,
    circle: false,
    line: false,
    points: false,
    rect: false,
  };

  const data = file.find((icon) => icon.name === name);

  if (!data) {
    console.error('Couldn\'t find Icon in the current dataset: ', name);
    return defaultValues;
  }

  return {
    viewBox: data.viewBox ?? defaultValues.viewBox,
    path: data.path ?? defaultValues.path,
    circle: data.circle ?? defaultValues.circle,
    line: data.line ?? defaultValues.line,
    points: data.points ?? defaultValues.points,
    rect: data.rect ?? defaultValues.rect,
  };
}
