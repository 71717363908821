import * as React from 'react';

import Colors from '../../../style/Colors';

import View from '../../../utilities/View';
import Container from '../../../utilities/Container';

import Logo from './Logo';
import Navigation from './Navigation';

export default function Header() {
  return (
    <View
      element="header"
      height={75}
      width="100%"
      backgroundColor={Colors.Background}
      borderBottom={`solid 2px ${Colors.Primary}`}
      position="fixed"
      top={0}
      zIndex={2147483647}
    >
      <Container>
        <View
          height={75}
          width="100%"
          direction="row"
          justify="space-between"
          align="center"
          gap={10}
        >
          <Logo />
          <Navigation />
        </View>
      </Container>
    </View>
  );
}
