import * as React from 'react';

import getMenu from '../../../api/getMenu';

import View from '../../../utilities/View';

import Title from '../../Atoms/Title';
import Link from '../../Atoms/Link';

export default function Links(props) {
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  const handleGridArea = (id) => {
    switch (id) {
      case 0:
        return '1 / 1 / 2 / 2';
      case 1:
        return '2 / 1 / 3 / 2';
      case 2:
        return '3 / 1 / 4 / 2';
      case 3:
        return '4 / 1 / 5 / 2';
      case 4:
        return '1 / 2 / 2 / 3';
      case 5:
        return '2 / 2 / 3 / 3';
      case 6:
        return '3 / 2 / 4 / 3';
      case 7:
        return '4 / 2 / 5 / 3';
      default:
        return '';
    }
  }

  React.useEffect(() => {
    const handleFetchData = async () => {
      const result = await getMenu('footer');

      await setData(result.links);

      if (result.links.length >= 1) setLoading(false);
    }

    handleFetchData();
  }, []);

  return (
    <View
      width="100%"
      gap={15}
    >
      <Title
        size="1.8em"
      >
        Links
      </Title>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridAutoRows: 'min-content',
          gap: 10,
          height: 'auto',
          width: '100%',
        }}
      >
        {
          data.map((item) => (
            <div
              key={item.id}
              style={{
                gridArea: handleGridArea(item.id),
                visibility: item.id >= 8 ? 'hidden' : 'visible',
                width: '100%',
              }}
            >
              <Link
                url={item.url.route}
              >
                {item.title}
              </Link>
            </div>
          ))
        }
      </div>
    </View>
  )
}
