import * as React from 'react';

import Colors from '../../../style/Colors';

import handleElementType from './functions/handleElementType';
import handleElementProperties from './functions/handleElementProperties';
import handlePendingDelay from './functions/handlePendingDelay';
import handleClassNames from './functions/handleClassNames';

import Paragraph from '../Paragraph';
import ActivityIndicator from '../ActivityIndicator';

export default function Button(props) {
  const height = 50;

  const [isHover, setHover] = React.useState(false);
  const [isPending, setPending] = React.useState(false);

  React.useEffect(() => {
    handlePendingDelay(props.isPending, (value) => setPending(value));
  }, [props.isPending])

  return React.createElement(
    handleElementType(props.onPress),
    {
      ...handleElementProperties(
        props.type,
        props.onPress,
        props.isPending,
      ),
      className: handleClassNames(props.isDisabled, isPending),
      id: props.id,
      onMouseEnter: () => setHover(true),
      onMouseLeave: () => setHover(false),
      style: {
        minHeight: height,
        height: 'auto',
        width: props.isJustified ? '100%' : 'auto',
        minWidth: height * 2.3,
        maxWidth: props.isJustified ? '100%' : 'max-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: height / 2,
        paddingRight: height / 2,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 7.5,
        backgroundColor: isHover ? Colors.Primary_Highlight : Colors.Primary,
        border: `solid 0px transparent`,
        textDecoration: 'none',
        transition: 'all .2s linear',
        fontSize: '0.9em',
      }
    },
    (
      <>
        {
          isPending
            ? (
              <ActivityIndicator
                isVisible
                color={Colors.Heading}
                size={23}
              />
            )
            : (
              <Paragraph
                color={Colors.Heading}
                family="Libre Baskerville"
                align="center"
              >
                {props.text}
              </Paragraph>
            )
        }
      </>
    )
  )
}
