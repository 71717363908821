/*
 * handleHeading
 *
 * Represent six levels of section headings. <h1> is the highest
 * section level and <h6> is the lowest.
 */
export default function handleHeading(heading) {
  switch (heading) {
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    case 6:
      return 'h6';
    default:
      return 'h1';
  }
}
