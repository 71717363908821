import handleElementType from './handleElementType';
import handleOnPress from './handleOnPress';

export default function handleElementProperties(
  type,
  onPress,
  isPending,
) {
  if (handleElementType(onPress) === 'button') {
    return ({
      type: type === 'submit' ? 'submit' : 'button',
      onClick: handleOnPress(onPress, isPending),
    })
  }

  // handleElementType(onPress) === 'a'
  return ({
    href: handleOnPress(onPress, isPending),
  })
}
