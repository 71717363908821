import * as React from 'react';

import Default from '../../style/Default';

import handleAlign from '../../style/functions/handleAlign';
import handleJustify from '../../style/functions/handleJustify';
import handlePadding from '../../style/functions/handlePadding';
import handleMargin from '../../style/functions/handleMargin';
import handleInset from '../../style/functions/handleInset';
import handleElement from '../../style/functions/handleElement';

export default function View(props) {
  const styling = {
    flexDirection: props.direction ?? 'column',
    gap: props.gap ?? 0,
    alignItems: handleAlign(props.align ?? 'start'),
    justifyContent: handleJustify(props.justify ?? 'start'),
    flexWrap: (props.wrap ?? false)
      ? 'wrap'
      : 'nowrap',
    flexGrow: (props.grow ?? false)
      ? 1
      : 0,
    width: props.width ?? 'auto',
    minWidth: props.minWidth ?? 'auto',
    maxWidth: props.maxWidth ?? 'none',
    height: props.height ?? 'auto',
    minHeight: props.minHeight ?? 'auto',
    maxHeight: props.maxHeight ?? 'none',
    aspectRatio: props.aspectRatio ?? 'auto',
    padding: handlePadding(
      props.paddingTop ?? 0,
      props.paddingRight ?? 0,
      props.paddingBottom ?? 0,
      props.paddingLeft ?? 0,
    ),
    margin: handleMargin(
      props.marginTop ?? 0,
      props.marginRight ?? 0,
      props.marginBottom ?? 0,
      props.marginLeft ?? 0,
    ),
    inset: handleInset(
      props.insetTop ?? 'initial',
      props.insetRight ?? 'initial',
      props.insetBottom ?? 'initial',
      props.insetLeft ?? 'initial',
    ),
    borderTop: props.borderTop
      ? props.borderTop
      : props.border ?? 'none',
    borderBottom: props.borderBottom
      ? props.borderBottom
      : props.border ?? 'none',
    borderLeft: props.borderLeft
      ? props.borderLeft
      : props.border ?? 'none',
    borderRight: props.borderRight
      ? props.borderRight
      : props.border ?? 'none',
    backgroundColor: `${props.backgroundColor ?? 'transparent'}${props.blur ? '72' : ''}`,
    borderRadius: props.borderRadius ?? 0,
    boxShadow: (props.shadow ?? false)
      ? Default.BoxShadow
      : 'none',
    zIndex: props.zIndex ?? 'auto',
    position: props.position ?? 'static',
    display: 'flex',
    top: props.top ?? 'initial',
    right: props.right ?? 'initial',
    left: props.left ?? 'initial',
    bottom: props.bottom ?? 'initial',
    backdropFilter: `${props.blur ? 'blur(15px)' : 'initial'}`,
    overflow: props.overflow ?? 'none',
  };

  return React.createElement(
    handleElement(props.element),
    {
      className: props.className ? props.className : props.blur && `${props.className} Blurred`,
      style: {
        ...styling,
      },
      /* This should only be used for dom element objects */
      ...props.domElements,
    },
    props.children,
  );
}
