export default function handleTextDecoration(type) {
  switch (type) {
    case 'line-through':
      return 'line-through';
    case 'underline':
      return 'underline';
    case 'overline':
      return 'overline';
    default:
      return 'unset';
  }
}
