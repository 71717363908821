import * as React from 'react';

import Button from '../../../../Atoms/Button'

export default function ItemButton(props) {
  return (
    <Button
      text={props.data && props.data.title}
      onPress={
        props.data
        && (
          props.data.url.route
          + (
            props.data.target && ('#' + props.data.target)
          )
        )
      }
    />
  )
}
