import * as React from 'react';
import { useLocation } from '@reach/router';

import useDimensions from '../../../../../hooks/useDimensions';

import Breakpoint from '../../../../../style/Breakpoints';
import Colors from '../../../../../style/Colors';

import View from '../../../../../utilities/View';

import Icon from '../../../../Atoms/Icon';

import Item from '../Item';
import Menu from './Menu';

export default function SideMenu(props) {
  const size = useDimensions();
  const location = useLocation();

  const [isHover, setHover] = React.useState(false);
  const [isSideMenuOpen, setSideMenuOpen] = React.useState(false);

  const hasHighlightedVisible = props.data.length >= 5
    ? size.width >= Breakpoint.sm
    : size.width >= Breakpoint.xs

  const handleOnClick = () => {
    setSideMenuOpen(!isSideMenuOpen);
  };

  React.useEffect(() => {
    if (!props.isVisible) setSideMenuOpen(false);
  }, [props.isVisible]);

  React.useEffect(() => {
    if (isSideMenuOpen) {
      // Set this in a function
      document.querySelector('body').style.overflowY = 'hidden';

      // TODO: This querySelector doesnt work
      document.querySelector('body').style.top = `${-(document.documentElement.scrollTop)}px`;
    } else {
      document.querySelector('body').style.overflowY = 'auto';
      document.querySelector('body').style.position = 'static';
    }
  }, [isSideMenuOpen]);

  React.useEffect(() => {
    if (isSideMenuOpen) handleOnClick();
  }, [location])

  if (!props.isVisible) return null;

  return (
    <>
      <View
        direction="row"
        align="center"
        justify="center"
        gap={15}
      >
        {
          hasHighlightedVisible && props.data
            .filter((object) => object.meta.button === true)
            .map((item) => (
              <Item
                key={item.id}
                data={item}
                type={item.meta.button ? 'Button' : 'Default'}
              />
            ))
        }
        <button
          type="button"
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          onClick={() => handleOnClick()}
          style={{
            height: 35,
            width: 35,
            backgroundColor: 'transparent',
            padding: isSideMenuOpen ? 5 : 0,
            margin: 0,
            border: 'none',
          }}
        >
          <Icon
            name={isSideMenuOpen ? 'Close' : 'Bars'}
            color={isHover ? Colors.Heading : Colors.Body}
          />
        </button>
      </View>
      <Menu
        isVisible={isSideMenuOpen}
        data={props.data}
      />
    </>
  );
}
