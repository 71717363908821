import * as React from 'react';

import getMenu from '../../../../api/getMenu';

import useDimensions from '../../../../hooks/useDimensions';

import Breakpoint from '../../../../style/Breakpoints';

import SideMenu from './SideMenu';
import HorizontalMenu from './HorizontalMenu';

export default function Navigation() {
  const size = useDimensions();

  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const handleFetchData = async () => {
      const result = await getMenu('header');

      await setData(result.links);

      if (result.links.length >= 1) setLoading(false);
    }

    handleFetchData();
  }, []);

  const isSideMenu = size.width <= Breakpoint.lg || data.length >= 5;

  return (
    <>
      <SideMenu
        data={data}
        isVisible={isSideMenu}
      />
      <HorizontalMenu
        data={data}
        isVisible={!isSideMenu}
      />
    </>
  )
}
