import * as React from 'react';
import parse from 'html-react-parser';

import Colors from '../../../style/Colors';

import SkeletonIndicator from '../SkeletonIndicator';

import handleTextDecoration from '../../../style/functions/handleTextDecoration';
import handleMaxLines from '../../../style/functions/handleMaxLines';

import '../../../style/css/WYSIWYG.css';

export default function Paragraph(props) {
  const maxLinesProperties = handleMaxLines(props.maxLines);

  const handleSkeletonIndicatorWidth = (index) => {
    switch (index) {
      case 1:
        return '80%';
      case 2:
        return '70%';
      default:
        return '100%';
    }
  }

  return (
    <p
      className={props.hasHTML ? 'wysiwyg' : ''}
      style={{
        padding: 0,
        margin: 0,
        width: '100%',
        color: props.color ?? Colors.Body,
        fontSize: props.size ?? '1.1em',
        fontWeight: props.weight ?? 100,
        textAlign: props.align ?? 'left',
        textDecoration: handleTextDecoration(props.decoration ?? 'unset'),
        fontFamily: props.family ?? 'Lato',
        letterSpacing: props.spacing ?? 0,
        display: 'flex',
        flexDirection: 'column',
        gap: (props.isLoading && props.lines) ? 10 : 0,
        ...maxLinesProperties,
      }}
    >
      {
        props.isLoading
          ? [...Array(props.lines ?? 1)].map((item, index) => (
            <React.Fragment
              key={index}
            >
              <SkeletonIndicator
                width={handleSkeletonIndicatorWidth(index)}
              />
            </React.Fragment>
          ))
          : props.hasHTML ? parse(props.children) : props.children
      }
    </p>
  );
}
