import Breakpoint from '../../../style/Breakpoints';

export default function handleStylingProperties(type, width) {
  /** LargeContainer */
  if (type === 'large') {
    const margin = width < Breakpoint.xxl ? 0 : width < Breakpoint.max ? '10%' : 'auto';

    return ({
      width: width < Breakpoint.xxl ? '100%' : '80%',
      maxWidth: 1650,
      marginLeft: margin,
      marginRight: margin,
    });
  }

  /** ContentContainer */
  if (type === 'content') {
    const margin = width < Breakpoint.max ? '17.5%' : 'auto';

    return ({
      width: '65%',
      maxWidth: 1400,
      marginLeft: margin,
      marginRight: margin,
    });
  }

  /** Mobile */
  if (width <= Breakpoint.sm) {
    return ({
      width: 'calc(100% - 30px)',
      marginLeft: 15,
      marginRight: 15,
    })
  }

  if (width <= Breakpoint.md) {
    return ({
      width: 'calc(100% - 150px)',
      maxWidth: 1600,
      marginLeft: 75,
      marginRight: 75,
    })
  }

  /** MainContainer */
  return ({
    width: '75%',
    maxWidth: 1600,
    marginLeft: width < Breakpoint.max ? '12.5%' : 'auto',
    marginRight: width < Breakpoint.max ? '12.5%' : 'auto',
  });
}
