export default function handleThrottling(callback, delay) {
  let throttling = false;

  return (...args) => {
    if (!throttling) {
      throttling = true;

      callback(...args);

      setTimeout(() => {
        throttling = false;
      }, delay);
    }
  };
}
