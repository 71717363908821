export default function handleMaxLines(maxLines) {
  if (maxLines !== undefined && typeof maxLines === 'number') {
    return {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': maxLines.toString(),
      '-webkit-box-orient': 'vertical',
    };
  }

  return null;
}
