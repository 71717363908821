import * as React from 'react';

import useDimensions from '../../hooks/useDimensions';

import handleStylingProperties from './functions/handleStylingProperties';

export default function Container(props) {
  const size = useDimensions();

  return (
    <div
      style={{
        width: '100vw',
        position: 'relative',
        overflow: props.isOverflowHidden ? 'hidden' : 'initial',
      }}
    >
      <div
        style={{
          ...handleStylingProperties(props.type, size.width),
          height: 'auto',
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
