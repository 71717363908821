export default function handleJustify(type) {
  switch (type) {
    case 'start':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'end':
      return 'flex-end';
    case 'space-between':
      return 'space-between';
    case 'space-around':
      return 'space-around';
    default:
      return 'flex-start';
  }
}
