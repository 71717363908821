import * as React from 'react';
import { Link } from "gatsby"

import Colors from '../../../../../style/Colors';

import Paragraph from '../../../../Atoms/Paragraph';

export default function ItemDefault(props) {
  const [isHover, setHover] = React.useState(false);

  return (
    <Link
      to={
        props.data
        && (
          props.data.url.route
          + props.data.target
        )
      }
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        width: props.isSideMenu ? '100%' : 'auto',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: props.isSideMenu
          ? 'flex-start'
          : 'center',
        paddingLeft: 5,
        paddingRight: 5,
        fontSize: '1em',
      }}
    >
      <Paragraph
        weight={300}
        size="1em"
        family="Libre Baskerville"
        align={props.isSideMenu ? 'left' : 'right'}
        color={
          (props.data && props.data.isActive) || isHover
            ? Colors.Heading
            : Colors.Body
        }
      >
        {props.data && props.data.title}
      </Paragraph>
    </Link>
  );
}
