/*
 * handleElement
 *
 * Return <Element /> based on the element prop.
 * It should only support content sectionings.
 */
export default function handleElement(element) {
  const sections = [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'hgroup',
    'main',
    'nav',
    'section',
    'search',
  ];

  if (sections.includes(element)) {
    return sections.find((item) => element === item);
  }

  return 'div';
}
