const Colors = {
  Heading: '#2A231D',
  Body: '#68615B',

  Background: '#F6EFE7',
  Primary: '#E4DCD1',
  Primary_Highlight: '#DAD2C8',

  White: '#FFFFFF',
  Critical: '#FF0000',
  Error: '#F94449',
};

export default Colors;
