import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import useDimensions from '../../../../../hooks/useDimensions';

import Breakpoint from '../../../../../style/Breakpoints';
import Colors from '../../../../../style/Colors';

import View from '../../../../../utilities/View';

import Item from '../Item';

export default function Menu(props) {
  const ref = React.useRef();

  const size = useDimensions();

  return (
    <AnimatePresence>
      {
        props.isVisible && (
          <motion.div
            ref={ref}
            style={{
              position: 'absolute',
              height: 'calc(100svh - 75px)',
              width: size.width < Breakpoint.xs
                ? '90vw'
                : 350,
              right: 0,
              top: 75,
              borderLeft: `solid 2px ${Colors.Primary}`,
            }}
            initial={{
              right: '-100%',
            }}
            animate={{
              right: 0,
            }}
            exit={{
              right: '-100%',
            }}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
          >
            <View
              element="nav"
              width="100%"
              height="100%"
              backgroundColor={Colors.Background}
              shade
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {
                  props.data.map((item) =>(
                    <View
                      key={item.id}
                      marginTop={item.meta.button ? 15 : 0}
                      paddingTop={item.meta.button ? 15 : 0}
                      borderTop={`solid ${item.meta.button ? 2 : 0}px ${Colors.Primary}`}
                      width="100%"
                    >
                      <Item
                        data={item}
                        type={item.meta.button ? 'Button' : 'Default'}
                        isSideMenu
                      />
                    </View>
                  ))
                }
              </div>
            </View>
          </motion.div>
        )
      }
    </AnimatePresence>
  );
}
