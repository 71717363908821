import * as React from 'react';

import View from '../../../utilities/View';

import Colors from '../../../style/Colors';

import Paragraph from '../../Atoms/Paragraph';

export default function Bottom(props) {
  return (
    <View
      height={50}
      width="100%"
      borderTop={`solid 2px ${Colors.Primary}`}
      justify="center"
    >
      <Paragraph
        size="0.9em"
      >
        {props.text}
      </Paragraph>
    </View>
  )
}
